import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Grid, Typography } from '@material-ui/core';

import { withLayout } from '../components/Layout';
import SEO from '../components/SEO';
import ShareButtons from '../components/ShareButtons';
import MoreMedia from '../components/MoreMedia';

const useStyles = makeStyles((theme) => ({
  wrapper: {
    marginLeft: -1 * theme.spacing(2),
    marginRight: -1 * theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      marginLeft: -1 * theme.spacing(3),
      marginRight: -1 * theme.spacing(3),
    },
    [theme.breakpoints.up('lg')]: {
      marginLeft: 0,
      marginRight: 0,
    },
  },
  player: {
    position: 'relative',
    paddingBottom: '56.25%',
    height: 0,
    overflow: 'hidden',
    maxWidth: '100%',
    marginTop: theme.spacing(-6),
    marginBottom: theme.spacing(2),
    [theme.breakpoints.up('lg')]: {
      marginTop: 0,
      marginBottom: theme.spacing(4),
    },
  },
  iframe: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: '#000',
  },
  title: {
    fontSize: `${20 / 16}rem`,
    [theme.breakpoints.up('md')]: {
      fontSize: theme.typography.h4.fontSize,
    },
  },
  timestamp: {
    cursor: 'default',
    color: theme.palette.grey[500],
  },
}));

const Page = () => {
  const classes = useStyles();

  return (
    <>
      <SEO title="Live Stream" />

      <div className={classes.wrapper}>
        <Box className={classes.player}>
          <iframe
            allowFullScreen
            title="Live Stream"
            frameBorder="0"
            src="https://www.youtube.com/embed/live_stream?channel=UCL8wQnv6qB7rZtEYfY3vPtw&autoplay=1&playsinline=1"
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            className={classes.iframe}
          />
        </Box>
      </div>

      <Box mb={8}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <Typography variant="h4" component="h1" className={classes.title}>
              RLCF Live Stream
            </Typography>

            <Typography variant="body2" className={classes.timestamp}>
              Sundays at 10:30am MST
            </Typography>
          </Grid>

          <Grid item xs={12} sm={6}>
            <ShareButtons title="RLCF Live Stream" align="right" />
          </Grid>
        </Grid>
      </Box>

      <MoreMedia />
    </>
  );
};

export default withLayout(Page);
